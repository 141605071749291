import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CorService {

  constructor() { }

  pegarThemas() {
    this.pegarCorPausa();
    this.pegarCorOcupado();
    this.pegarCorLivre();
    this.pegarCorIndisponivel();
    this.pegarCorAtendimento();
    this.pegarCorOnhold();
  }

  pegarCorPausa() {
    let root = document.documentElement;
    if(typeof localStorage.getItem('corPausa') === 'string') {
      let cor = localStorage.getItem('corPausa');
      root.style.setProperty('--pausado', cor);
    } else {
      let cor = 'rgb(238, 238, 238)';
      root.style.setProperty('--pausado', cor);
    }
  }

  pegarCorOcupado() {
    let root = document.documentElement;
    if(typeof localStorage.getItem('corOcupado') === 'string') {
      let cor = localStorage.getItem('corOcupado');
      root.style.setProperty('--ocupado', cor);
    } else {
      let cor = 'red';
      root.style.setProperty('--ocupado', cor);
    }
  }

  pegarCorLivre() {
    let root = document.documentElement;
    if(typeof localStorage.getItem('corLivre') === 'string') {
      let cor = localStorage.getItem('corLivre');
      root.style.setProperty('--disponivel', cor);
    } else {
      let cor = 'rgb(102, 255, 0)';
      root.style.setProperty('--disponivel', cor);
    }
  }

  pegarCorIndisponivel() {
    let root = document.documentElement;
    if(typeof localStorage.getItem('corIndisponivel') === 'string') {
      let cor = localStorage.getItem('corIndisponivel');
      root.style.setProperty('--indisponivel', cor);
    } else {
      let cor = 'rgb(185, 185, 185)';
      root.style.setProperty('--indisponivel', cor);
    }
  }

  pegarCorAtendimento() {
    let root = document.documentElement;
    if(typeof localStorage.getItem('corAtendimento') === 'string') {
      let cor = localStorage.getItem('corAtendimento');
      root.style.setProperty('--atendimento', cor);
    } else {
      let cor = 'rgb(255, 174, 0)';
      root.style.setProperty('--atendimento', cor);
    }
  }

  pegarCorOnhold() {
    let root = document.documentElement;
    if(typeof localStorage.getItem('corOnhold') === 'string') {
      let cor = localStorage.getItem('corOnhold');
      root.style.setProperty('--onhold', cor);
    } else {
      let cor = 'blue';
      root.style.setProperty('--onhold', cor);
    }
  }

}
