import { Component, OnInit } from '@angular/core';
import { VerificaTokenService } from './servicos/autenticacao/verifica-token.service';
import { AuthServiceService } from './servicos/autenticacao/auth-service.service';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CorService } from './servicos/cor/cor.service';
import { AppService } from './servicos/app/app.service';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
// import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(
    private authService: AuthServiceService,
    private verifica: VerificaTokenService,
    private corService: CorService,
    private appService: AppService,
    private titleService: Title
  ) { }

  logado: BehaviorSubject<boolean>;
  on: Observable<boolean>;
  dir: Observable<boolean>;
  user: BehaviorSubject<any>;

  deslogar() {
    this.authService.deslogar();
  }

  ngOnInit(): void {
    this.authService.usuarioAutenticado();
    this.authService.onlinePermission();
    this.authService.DirPermission();
    this.on = this.authService.accesOn;
    this.dir = this.authService.accesDir;
    this.user = this.authService.infoUser;
    this.logado = this.authService.logado;
    this.corService.pegarThemas();
    this.pegarTitle();
  }

  pegarTitle() {
    this.appService.getInfos().pipe(take(1)).subscribe((resp:any) => {
      this.setTitle(resp?.Titulo)
    })
  }

  setTitle(newTitle:string) {
    this.titleService.setTitle(newTitle)
  }
}
