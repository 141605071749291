import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { TimePipe } from './pipes/time.pipe';

import { AuthServiceService } from './servicos/autenticacao/auth-service.service';
import { AuthGuard } from './guard/auth-guard';
import { MaterialModule } from './material/material.module';
import { EncryService } from './servicos/crypt/encry.service';
import { FormsModule } from '@angular/forms';

import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData, APP_BASE_HREF, DatePipe, CurrencyPipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(localePtBr, 'pt');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    TimePipe,
    AuthServiceService,
    AuthGuard, EncryService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: APP_BASE_HREF, useValue: '/gnew' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
