import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VerificaTokenService {

  constructor() { }

   verificaLocal() {
    if (localStorage.getItem('token') != null) {
      return true;
    } else {
      return false;
    }
  }
}
