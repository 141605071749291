import { Injectable } from '@angular/core';
import { Suporte } from 'src/app/ajuda/ajuda.component';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpService) { }

  getInfos() {
    this.http.get(`master/manutencao/info/gerais/`).subscribe(resp => {
      console.log(resp);
    });;
    return this.http.get(`master/manutencao/info/gerais/`);
  }

  getSuporte() : Suporte | any {
    this.http.get(`master/manutencao/info/suporte/`).subscribe(resp => {
      console.log(resp);
    });
    return this.http.get(`master/manutencao/info/suporte/`);
  }
  
}
