import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EncryService } from '../crypt/encry.service';

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(
    private http: HttpClient,
    private encDecService: EncryService
  ) { }

  token = `${localStorage.getItem('tokenMaster') ? localStorage.getItem('tokenMaster') : environment.local}`;

  // Header padrão
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  post(serviceName:any, dados:any) {
    return this.http.post(`${localStorage.getItem('server') ? (localStorage.getItem('server') + serviceName)
    : (environment.apiRest + serviceName)}`, dados, this.httpOptions);
  }

  get(serviceName:any) {
    return this.http.get(`${localStorage.getItem('server') ? (localStorage.getItem('server') + serviceName)
    : (environment.apiRest+ serviceName)}`, this.httpOptions);
  }

  postToken(serviceName:any, dados:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Token ${this.encDecService.get('123456$#@$^@1ERF', localStorage.getItem('token'))}`,
      })
    };
   
    return this.http.post(`${localStorage.getItem('serverMaster') ? (localStorage.getItem('serverMaster') + serviceName)
    : (environment.apiRest + serviceName)}`, dados, httpOptions);
  }

  getToken(serviceName:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Token ${this.encDecService.get('123456$#@$^@1ERF', localStorage.getItem('token'))}`,
      })
    };
    return this.http.get(`${localStorage.getItem('server')  ? (localStorage.getItem('server')  + serviceName)
    : (environment.apiRest + serviceName)}`, httpOptions);
  }

  updateToken(serviceName:any, dados:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Token ${this.encDecService.get('123456$#@$^@1ERF', localStorage.getItem('token'))}`,
      })
    };
    return this.http.put(`${localStorage.getItem('serverMaster') ? (localStorage.getItem('serverMaster') + serviceName)
    : (environment.apiRest + serviceName)}`, dados, httpOptions);
  }

  patchToken(serviceName:any, dados:any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Token ${this.encDecService.get('123456$#@$^@1ERF', localStorage.getItem('token'))}`,
      })
    };
    return this.http.patch(`${localStorage.getItem('serverMaster') ? (localStorage.getItem('serverMaster') + serviceName)
    : (environment.apiRest + serviceName)}`, dados, httpOptions);
  }

  deleteToken(serviceName:any /*,dados*/) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: `Token ${this.encDecService.get('123456$#@$^@1ERF', localStorage.getItem('token'))}`,
      })
    };
    // return this.http.delete(`${localStorage.getItem('server') ? (localStorage.getItem('server') + serviceName)
    // : (environment.apiRest + serviceName)}?id=${dados}`, httpOptions);
    return this.http.delete(`${localStorage.getItem('serverMaster') ? (localStorage.getItem('serverMaster') + serviceName)
    : (environment.apiRest + serviceName)}`, httpOptions);
  }

}
